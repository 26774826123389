<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close(null)">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>{{message}}</p>
</div>
<div class="modal-footer">
    <button type="button" (click)="activeModal.close(false)" class="btn btn btn-secondary"><span class="ion ion-md-hand mr-2"></span>No</button>
    <button type="button" (click)="activeModal.close(true)" class="btn btn btn-success"><span class="ion ion-md-checkmark-circle-outline mr-2"></span>Yes</button>
</div>