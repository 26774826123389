
//#region  Import-Angular
import { Component, Input, OnInit , OnChanges, SimpleChanges} from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import {  Subscription } from 'rxjs';
//#endregion

@Component({
  selector: 'app-global-manage-address',
  templateUrl: './global-manage-address.component.html',
  styleUrls: ['./global-manage-address.component.css']
})
export class GlobalManageAddressComponent implements OnInit, OnChanges {

  //#region Variables
  public isAddOrUpdateOfficeAddressDetailsValid = true;
  public addOrUpdateOfficeAddressDetailsForm: FormGroup;
  public eventsSubscription: Subscription;
  @Input() isSaveButtonClicked: number;
  //#endregion

  //#region  Constructor
  constructor(private controlContainer: ControlContainer) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSaveButtonClicked.currentValue) {
      this.isAddOrUpdateOfficeAddressDetailsValid = this.addOrUpdateOfficeAddressDetailsForm?.valid;
    }
  }

  ngOnInit(){
    this.addOrUpdateOfficeAddressDetailsForm = this.controlContainer.control as FormGroup;
    // console.log(this.addOrUpdateOfficeAddressDetailsForm.value);
  }
  //#endregion
}
