import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  title = '';
  message = '';
  type = 'success';
  tapToDismiss = true;
  closeButton = true;
  progressBar = true;
  preventDuplicates = false;
  newestOnTop = true;
  progressAnimation: any = 'decreasing';
  positionClass = 'toast-top-right';

  constructor(public toastrService: ToastrService) { }

  public showSuccessNotification(message: string) {
    if (!message) { return; }

    const options = {
      tapToDismiss: this.tapToDismiss,
      closeButton: this.closeButton,
      progressBar: this.progressBar,
      progressAnimation: this.progressAnimation,
      positionClass: this.positionClass,
      rtl: true
    };

    // `newestOnTop` and `preventDuplicates` options must be set on global config
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;

    // this.toastrService['success'](message, 'Success', options);
    this.toastrService['success'](message, 'Success', options);
  }

  public showInfoNotification(message: string) {
    if (!message) { return; }

    const options = {
      tapToDismiss: this.tapToDismiss,
      closeButton: this.closeButton,
      progressBar: this.progressBar,
      progressAnimation: this.progressAnimation,
      positionClass: this.positionClass,
      rtl: true
    };

    // `newestOnTop` and `preventDuplicates` options must be set on global config
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;

    // this.toastrService['success'](message, 'Success', options);
    this.toastrService['info'](message, 'Info', options);
  }

  public showErrorNotification(message: string) {
    if (!message) { return; }

    const options = {
      tapToDismiss: this.tapToDismiss,
      closeButton: this.closeButton,
      progressBar: this.progressBar,
      progressAnimation: this.progressAnimation,
      positionClass: this.positionClass,
      rtl: true
    };

    // `newestOnTop` and `preventDuplicates` options must be set on global config
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;

    // this.toastrService['success'](message, 'Success', options);
    this.toastrService['error'](message, 'Error', options);
  }

  public showWarningNotification(message: string) {
    if (!message) { return; }

    const options = {
      tapToDismiss: this.tapToDismiss,
      closeButton: this.closeButton,
      progressBar: this.progressBar,
      progressAnimation: this.progressAnimation,
      positionClass: this.positionClass,
      rtl: true
    };

    // `newestOnTop` and `preventDuplicates` options must be set on global config
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.preventDuplicates = false;

    // this.toastrService['success'](message, 'Success', options);
    this.toastrService['warning'](message, 'Warning', options);
  }
}
