import { MedicineDbModule } from './medicine-db/medicine-db.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

// *******************************************************************************
// Layouts

import { Layout1Component } from './layout/layout-1/layout-1.component';

// *******************************************************************************
// Pages

import { HomeComponent } from './home/home.component';
import { Page2Component } from './page-2/page-2.component';

// *******************************************************************************
// Routes

const routes: Routes = [
  {
    path: '',
    redirectTo: 'medicine-db',
    pathMatch:'full'
  },

  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },

  {
    path: 'medicine-db',
    loadChildren: () => import('./medicine-db/medicine-db.module').then(m => m.MedicineDbModule)
  },

  {
    path: 'home', component: Layout1Component, pathMatch: 'full', children: [
      { path: '', component: HomeComponent },
    ]
  },

  {
    path: 'page-2', component: Layout1Component, children: [
      { path: '', component: Page2Component },
    ]
  },

  // 404 Not Found page
  { path: '**', component: NotFoundComponent }

];

// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
