import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import apiInfo from 'src/assets/business/api.json';
import { ApiRequest, RequestType } from '@models/util/api-adapter-service/api-request.model';
import { Parameter } from '@models/util/api-adapter-service/parameter.model';
import { ServerInBuiltError } from '@models/util/api-adapter-service/server-in-built-error.model';
import { AppRequest } from '@models/util/api-dto/request-models/app-request';
import { AppResponse } from '@models/util/api-dto/response-models/app-response';

@Injectable({ providedIn: 'root' })

export class ApiAdapterService {

  private defaultVersion = environment.DEFAULT_API_VERSION;
  private queryCount = 0;

  constructor(private httpClient: HttpClient) { }

  public executeApiRequest<T>(urlCode: string, parameterList: Array<Parameter>, version?: string): Observable<AppResponse<any> | ServerInBuiltError> {
    let name: string;
    let value: any;
    let parameter: Parameter;
    let bodyData: {};

    version = version ?? this.defaultVersion;

    const apiRequestInfo = apiInfo;

    // tslint:disable-next-line: no-string-literal
    const urlDetails = apiRequestInfo['urlInfo'].find(urlInfo => urlInfo.operationId === urlCode && urlInfo.version === version) as ApiRequest;
    let url = urlDetails.path;
    const module = urlDetails.module ?? null;
    const httpRequest = urlDetails.httpRequest;
    version = urlDetails.version ?? this.defaultVersion;

    if (!this.propertiesValidation(urlDetails.requestTypeList, parameterList)) {
      window.alert(`Parameters you entered are not valid!, Try again with valid parameters`);
      return null;
    }

    let formData = new FormData();
    urlDetails.requestTypeList.filter(rt => rt.in !== 'header').forEach(requestType => {

      ;

      parameter = parameterList
        .find(parameter => parameter.name === requestType.name) as Parameter;

      if (!parameter && requestType.required && requestType.in !== 'header') {
        window.alert(`Parameter "${requestType.name}" is required!, Try again with "${requestType.name}"`);
      } else if (!parameter && !requestType.required) {
        // continue;
      } else {
        name = parameter.name;
        value = parameter.value;

        let expression: 'path' | 'body' | 'query' | 'formData' | 'header';
        expression = requestType.in;

        switch (expression) {
          case 'body': {
            bodyData = value;
            break;
          }
          case 'path': {
            url = url.replace(`{${name}}`, value);
            break;
          }
          case 'query': {
            url = this.setQuery(name, value, url);
            break;
          }
          case 'formData': {
            formData = this.setFormData(requestType, parameter, formData);
            break;
          }
          default: {
            break;
          }
        }
      }

      parameter = undefined;
    });
    this.queryCount = 0;

    const getBaseUrl = this.getBaseUrl();

    url = `${getBaseUrl}/${version}${url}`;

    const appRequest: AppRequest<any> = { data: bodyData };

    const options = {
      body: bodyData === undefined ? formData : appRequest,
      responseType: 'json',
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    return this.httpClient
      .request<AppResponse<T>>(httpRequest, url, {
        body: bodyData === undefined ? formData : appRequest,
        responseType: 'json',
        headers: new HttpHeaders().set('Content-Type', 'application/json')
        })
      .pipe(
        catchError((err: HttpErrorResponse) => {

          if (err.error instanceof ErrorEvent) {
            return of<AppResponse<any>>({
              error: {
                title: 'Network error!',
                detail: err.message,
                source: err.statusText,
                status: err.status.toString()
              },
              meta: {
                code: err.status,
                message: err.message
              }
            });
          }

          return of<ServerInBuiltError>(err.error);
        }));
  }

  private setQuery(name: string, value: any, url: string): string {

    url = (this.queryCount === 0) ? `${url}?${name}=${value}` : `${url}&${name}=${value}`;
    this.queryCount++;
    return url;
  }

  private getBaseUrl(): string {
    return environment.BASE_URLS.BUSINESS;
  }

  private propertiesValidation(requestType: Array<RequestType>, parameterList: Array<Parameter>): boolean {
    let request_type = new Array<any>();
    let parameter_name = new Array<any>();

    requestType.forEach(rType => {
      request_type.push(rType.name);
    });

    parameterList.forEach(parameterName => {
      parameter_name.push(parameterName.name);

    });

    const result = parameter_name.every(val => request_type.includes(val));
    return result;
  }

  private setFormData(requestType: RequestType, parameter: Parameter, formData: FormData): FormData {
    if (requestType.type === 'array') {
      for (let i = 0; parameter.value.length > i; i++) {
        formData.append(parameter.name, parameter.value[i], parameter.value[i].name);
      }
    } else {
      formData.append(parameter.name, parameter.value);
    }
    return formData;
  }
}
