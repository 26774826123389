import { JwtAuthGuard } from './service/auth/jwt-auth-guard';
import { JwtAuthService } from './service/auth/jwt-auth.service';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// *******************************************************************************
// NgBootstrap

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LaddaModule } from 'angular2-ladda';

// *******************************************************************************
// App

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';

// *******************************************************************************
// Pages

import { HomeComponent } from './home/home.component';
import { Page2Component } from './page-2/page-2.component';
import { ApiAdapterService } from './service/util/api-adapter.service';
import { NmraCurrencyPipe } from '@pipes/nmra-currency.pipe';
import { NmraDatePipe } from '@pipes/nmra-date.pipe';
import { NotificationService } from './service/util/notification.service';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationBoxComponent } from '@global/ui-blocks/confirmation-box/confirmation-box.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { BlockUIModule } from 'ng-block-ui';
import {
  GlobalManageSiteOtherAddressComponent
} from '@global/business/address/component/global-manage-site-other-address/global-manage-site-other-address.component';
import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { PermissionService } from './service/auth/permission.service';
import { KeycloakOnLoad } from 'keycloak-js';
import * as keycloak from '@assets/auth/keycloak-configs.json';
import { JwtPermissionService } from './service/auth/jwt-permission.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PermissionDirective } from './directives/permission.directive';
import { JwtInterceptor } from './interceptors/jwt.interceptor';


// *******************************************************************************
//


export function authInitializer(Keyloack: KeycloakService, permissionService: PermissionService) {

  const options: KeycloakOptions = {
    config: keycloak.KEYCLOAK.config,
    initOptions: {
      onLoad: keycloak.KEYCLOAK.initOptions.onLoad as KeycloakOnLoad,
      silentCheckSsoRedirectUri:
        window.location.origin + '/assets/silent-check-sso.html',
      checkLoginIframe: keycloak.KEYCLOAK.initOptions.checkLoginIframe
    },
    bearerExcludedUrls: keycloak.KEYCLOAK.bearerExcludedUrls
  };

  return (): Promise<any> => Keyloack.init(options).then((authenticated) => {
    if (authenticated) {
      const username = Keyloack.getKeycloakInstance().tokenParsed['preferred_username'];
      return new Promise((resolve) => {
        const loadUserPermissions = permissionService.loadUserPermissions(username);
        const loadStaticPermissions = permissionService.loadStaticPermissions('./assets/configs/permission-configs.json');
        Promise.all([loadUserPermissions, loadStaticPermissions]).then((values) => {
          resolve(values);
        });
      });
    }
  }).catch(() => {
    alert('failed to initialize');
  });
}


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    NmraCurrencyPipe,
    NmraDatePipe,

    // Pages
    HomeComponent,
    Page2Component,
    ConfirmationBoxComponent,

    // Directives

  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    BlockUIModule.forRoot(),
    // App
    AppRoutingModule,
    LayoutModule, FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      toastClass: 'toast'
    }),
    ConfirmationPopoverModule.forRoot({
      cancelButtonType: 'secondary btn-sm',
      confirmButtonType: 'success btn-sm',
      confirmText: '&nbsp;&nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;',
      cancelText: '&nbsp;&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;',
      placement: 'top',
      popoverTitle: 'Please Confirm',
      popoverMessage: 'Do you need to proceed?'
    }),
  ],

  providers: [
    Title,
    AppService,
    ApiAdapterService,
    JwtAuthService,
    JwtPermissionService,
    JwtAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],

  entryComponents: [
    ConfirmationBoxComponent,
    GlobalManageSiteOtherAddressComponent
  ],

  bootstrap: [
    AppComponent
  ],
  exports: [

  ]
})
export class AppModule { }
