<nav class="navbar navbar-expand-lg align-items-lg-center container-p-x" [ngClass]="currentBg()">

    <!-- Brand demo (see demo.css) -->
    <a routerLink="/" class="navbar-brand app-brand demo d-lg-none py-0 mr-4">
        <span>
            <!-- <svg viewBox="0 0 148 80" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                    <linearGradient id="a" x1="46.49" x2="62.46" y1="53.39" y2="48.2" gradientUnits="userSpaceOnUse">
                        <stop stop-opacity=".25" offset="0"></stop>
                        <stop stop-opacity=".1" offset=".3"></stop>
                        <stop stop-opacity="0" offset=".9"></stop>
                    </linearGradient>
                    <linearGradient id="e" x1="76.9" x2="92.64" y1="26.38" y2="31.49" xlink:href="#a"></linearGradient>
                    <linearGradient id="d" x1="107.12" x2="122.74" y1="53.41" y2="48.33" xlink:href="#a">
                    </linearGradient>
                </defs>
                <path style="fill: #fff;" transform="translate(-.1)"
                    d="M121.36,0,104.42,45.08,88.71,3.28A5.09,5.09,0,0,0,83.93,0H64.27A5.09,5.09,0,0,0,59.5,3.28L43.79,45.08,26.85,0H.1L29.43,76.74A5.09,5.09,0,0,0,34.19,80H53.39a5.09,5.09,0,0,0,4.77-3.26L74.1,35l16,41.74A5.09,5.09,0,0,0,94.82,80h18.95a5.09,5.09,0,0,0,4.76-3.24L148.1,0Z">
                </path>
                <path transform="translate(-.1)" d="M52.19,22.73l-8.4,22.35L56.51,78.94a5,5,0,0,0,1.64-2.19l7.34-19.2Z"
                    fill="url(#a)"></path>
                <path transform="translate(-.1)" d="M95.73,22l-7-18.69a5,5,0,0,0-1.64-2.21L74.1,35l8.33,21.79Z"
                    fill="url(#e)"></path>
                <path transform="translate(-.1)" d="M112.73,23l-8.31,22.12,12.66,33.7a5,5,0,0,0,1.45-2l7.3-18.93Z"
                    fill="url(#d)"></path>
            </svg> -->
            <img src="assets/img/nmra-logo.jpg" height="50" alt="">
        </span>
        <span class="app-brand-text demo font-weight-normal ml-2"></span>
    </a>

    <!-- Sidenav toggle (see demo.css) -->
    <div class="layout-sidenav-toggle navbar-nav d-lg-none align-items-lg-center mr-auto" *ngIf="sidenavToggle">
        <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" (click)="toggleSidenav()">
            <i class="ion ion-md-menu text-large align-middle"></i>
        </a>
    </div>

    <button class="navbar-toggler" type="button" (click)="isExpanded = !isExpanded">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse collapse" [ngbCollapse]="!isExpanded">
        <!-- Divider -->
        <hr class="d-lg-none w-100 my-2">

        <div class="navbar-nav align-items-lg-center">
            <!-- Search -->
            <!-- <label class="nav-item navbar-text navbar-search-box p-0 active">
                <i class="ion ion-ios-search navbar-icon align-middle"></i>
                <span class="navbar-search-input pl-2">
                    <input type="text" class="form-control navbar-text mx-2" placeholder="Search..."
                        style="width:200px">
                </span>
            </label> -->
            <!-- <h2 class="mb-0 ml-4 pl-4">User Management</h2> -->
        </div>

        <div class="navbar-nav align-items-lg-center ml-auto">
            <!-- <div ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'" class="demo-navbar-notifications nav-item mr-lg-3">
                <a ngbDropdownToggle class="nav-link hide-arrow" href="javascript:void(0)">
                    <i class="ion ion-md-notifications-outline navbar-icon align-middle"></i>
                    <span class="badge badge-primary badge-dot indicator"></span>
                    <span class="d-lg-none align-middle">&nbsp; Notifications</span>
                </a>
                <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
                    <div class="bg-primary text-center text-white font-weight-bold p-3">
                        4 New Notifications
                    </div>
                    <div class="list-group list-group-flush">
                        <a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">
                            <div class="ui-icon ui-icon-sm ion ion-md-home bg-secondary border-0 text-white"></div>
                            <div class="media-body line-height-condenced ml-3">
                                <div class="text-body">Login from 192.168.1.1</div>
                                <div class="text-light small mt-1">
                                    Aliquam ex eros, imperdiet vulputate hendrerit et.
                                </div>
                                <div class="text-light small mt-1">12h ago</div>
                            </div>
                        </a>

                        <a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">
                            <div class="ui-icon ui-icon-sm ion ion-md-person-add bg-info border-0 text-white"></div>
                            <div class="media-body line-height-condenced ml-3">
                                <div class="text-body">You have <strong>4</strong> new followers</div>
                                <div class="text-light small mt-1">
                                    Phasellus nunc nisl, posuere cursus pretium nec, dictum vehicula tellus.
                                </div>
                            </div>
                        </a>

                        <a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">
                            <div class="ui-icon ui-icon-sm ion ion-md-power bg-danger border-0 text-white"></div>
                            <div class="media-body line-height-condenced ml-3">
                                <div class="text-body">Server restarted</div>
                                <div class="text-light small mt-1">
                                    19h ago
                                </div>
                            </div>
                        </a>

                        <a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">
                            <div class="ui-icon ui-icon-sm ion ion-md-warning bg-warning border-0 text-body"></div>
                            <div class="media-body line-height-condenced ml-3">
                                <div class="text-body">99% server load</div>
                                <div class="text-light small mt-1">
                                    Etiam nec fringilla magna. Donec mi metus.
                                </div>
                                <div class="text-light small mt-1">
                                    20h ago
                                </div>
                            </div>
                        </a>
                    </div>

                    <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">Show all
                        notifications</a>
                </div>
            </div> -->

            <!-- <div ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'" class="demo-navbar-messages nav-item mr-lg-3">
                <a ngbDropdownToggle class="nav-link hide-arrow" href="javascript:void(0)">
                    <i class="ion ion-ios-mail navbar-icon align-middle"></i>
                    <span class="badge badge-primary badge-dot indicator"></span>
                    <span class="d-lg-none align-middle">&nbsp; Messages</span>
                </a>
                <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
                    <div class="bg-primary text-center text-white font-weight-bold p-3">
                        4 New Messages
                    </div>
                    <div class="list-group list-group-flush">
                        <a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">
                            <img src="assets/img/avatars/6-small.png" class="d-block ui-w-40 rounded-circle" alt>
                            <div class="media-body ml-3">
                                <div class="text-body line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>
                                <div class="text-light small mt-1">
                                    Mae Gibson &nbsp;·&nbsp; 58m ago
                                </div>
                            </div>
                        </a>

                        <a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">
                            <img src="assets/img/avatars/4-small.png" class="d-block ui-w-40 rounded-circle" alt>
                            <div class="media-body ml-3">
                                <div class="text-body line-height-condenced">Mea et legere fuisset, ius amet purto luptatum te.</div>
                                <div class="text-light small mt-1">
                                    Kenneth Frazier &nbsp;·&nbsp; 1h ago
                                </div>
                            </div>
                        </a>

                        <a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">
                            <img src="assets/img/avatars/5-small.png" class="d-block ui-w-40 rounded-circle" alt>
                            <div class="media-body ml-3">
                                <div class="text-body line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>
                                <div class="text-light small mt-1">
                                    Nelle Maxwell &nbsp;·&nbsp; 2h ago
                                </div>
                            </div>
                        </a>

                        <a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">
                            <img src="assets/img/avatars/11-small.png" class="d-block ui-w-40 rounded-circle" alt>
                            <div class="media-body ml-3">
                                <div class="text-body line-height-condenced">Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.</div>
                                <div class="text-light small mt-1">
                                    Belle Ross &nbsp;·&nbsp; 5h ago
                                </div>
                            </div>
                        </a>
                    </div>

                    <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">Show all
                        messages</a>
                </div>
            </div> -->

            <!-- Divider -->
            <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|
            </div>

            <div ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'" class="demo-navbar-user nav-item">
                <a ngbDropdownToggle class="nav-link" href="javascript:void(0)">
                    <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                        <img src="assets/img/avatars/dummy-user-image.png" alt class="d-block ui-w-30 rounded-circle">
                        <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{loggedInUser.fullName}}</span>
                    </span>
                </a>
                <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
                    <!-- <a href="http://localhost:4201/medicine-db/demo/sample-one" target="_blank" class="dropdown-item"><i
                            class="ion ion-ios-person text-lightest"></i> &nbsp; Demo Sample 1</a>
                    <a href="http://localhost:4201/medicine-db/demo/sample-two" target="_blank" class="dropdown-item"><i
                            class="ion ion-ios-person text-lightest"></i> &nbsp; Demo Sample 2</a>
                    <a href="http://localhost:4201/medicine-db/demo/sample-three" target="_blank" class="dropdown-item"><i class="ion ion-ios-person text-lightest"></i> &nbsp; Demo Sample 3</a>
                    <a href="http://localhost:4201/medicine-db/demo/sample-four" target="_blank" class="dropdown-item"><i class="ion ion-ios-person text-lightest"></i> &nbsp; Demo Sample 4</a> -->

                    <!-- <div class="dropdown-divider"></div> -->
                    <a routerLink="/medicine-db/profile" class="dropdown-item" *permission="'ACT_MY_PROFILE_VIEW'"><i class="ion ion-ios-person text-lightest"></i>
                        &nbsp; My profile
                    </a>
                    <!-- <a href="javascript:void(0)" class="dropdown-item"><i class="ion ion-ios-mail text-lightest"></i>
                        &nbsp; Messages</a>
                    <a href="javascript:void(0)" class="dropdown-item"><i class="ion ion-md-settings text-lightest"></i>
                        &nbsp; Account settings</a> -->
                    <div class="dropdown-divider"></div>
                    <a href="javascript:void(0)" class="dropdown-item" (click)="onLogoutClick()">
                        <i class="ion ion-ios-log-out text-danger"></i> &nbsp; Log Out
                    </a>
                </div>
            </div>
        </div>
    </div>
</nav>
