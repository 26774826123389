import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { JwtPermissionService } from "./jwt-permission.service";

export class JwtAuthGuard implements CanActivate {
    constructor(private router: Router, private permissionService: JwtPermissionService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!route.data.permission) {
            return false;
        }

        if (route.data?.permission && !this.permissionService.isPermitted(route.data.permission)) {
            this.router.navigate(['/login']);
            return false;
        }

        return true;
    }
}