<form [formGroup]="countryForm">

    <ng-select [items]="selectOptions" bindLabel="label" bindValue="value" formControlName="countryId" (focus)="onFocus()" [ngClass]="(!iscountryFormValid && countryForm?.controls?.countryId?.errors)?'is-invalid':''" [multiple]="false" placeholder="Select Country">
        <ng-template ng-label-tmp let-item="item">
            <span class="ng-value-label"><img *ngIf="item.flag" [src]="item.flag"
           height="16px" class="align-middle mr-1"> {{item.label}}</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
            <span class="ng-value-label"><img [src]="item.flag"
           height="16px" class="align-middle mr-1"> {{item.label}}</span>
        </ng-template>
    </ng-select>

    <small class="invalid-message" *ngIf="(!iscountryFormValid && countryForm?.controls?.countryId?.errors)">Country is Required</small>

</form>
