import { NotificationService } from 'src/app/service/util/notification.service';
import { Component, Input, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedInUser } from '@models/business/logged-in-user.model';
import { JwtAuthService } from 'src/app/service/auth/jwt-auth.service';
import { AppService } from '../../app.service';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'app-layout-navbar',
  templateUrl: './layout-navbar.component.html',
  styles: [':host { display: block; }']
})
export class LayoutNavbarComponent implements OnInit {
  isExpanded = false;
  isRTL: boolean;

  @Input() sidenavToggle = true;

  @HostBinding('class.layout-navbar') hostClassMain = true;

  public loggedInUser: LoggedInUser = {} as LoggedInUser;

  constructor(private appService: AppService, private layoutService: LayoutService, private jwtAuthService: JwtAuthService, private router: Router, private notificationService: NotificationService,) {
    this.isRTL = appService.isRTL;
  }

  ngOnInit(): void {
    this.loadLoggedInUser();
  }

  currentBg() {
    return `bg-${this.appService.layoutNavbarBg}`;
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }

  onLogoutClick(): void {
    this.jwtAuthService.logOut();
    this.notificationService.showSuccessNotification('You have logged out successfully');
    this.router.navigate(['/login']);
  }

  private loadLoggedInUser(): void {
    this.loggedInUser = this.jwtAuthService.fetchUser();
  }
}
