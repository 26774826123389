
//#region  Import-Angular
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GlobalAddress } from '@models/business/global-address';
//#endregion
@Component({
  selector: 'app-global-manage-site-other-address',
  templateUrl: './global-manage-site-other-address.component.html',
  styleUrls: ['./global-manage-site-other-address.component.css']
})
export class GlobalManageSiteOtherAddressComponent implements OnInit {
  @Input() public addressDetails: GlobalAddress;
  @Output() onsaveAddressClick = new EventEmitter<GlobalAddress>();
  public isSiteAddressSaveButtonClicked = 0;
  public manageAddressForm: FormGroup;
  public isManageAddressFormValid = true;
  public isSaveInPrograss = false;
  //#region  constructor
  constructor(
    private formBuilder: FormBuilder) { }
  ngOnInit() {
    this.createForm();
    if (this.addressDetails.id > -1) {
      this.loadForm();
    }
  }

  public get manageAddressFormControls() {
    return this.manageAddressForm.controls;
  }
  //#endregion

  //#region event
  onCancelClick() {
    this.onsaveAddressClick.emit({ id: -2 });
  }

  onSaveClick() {
    if (this.isSaveInPrograss) { return; }
    this.isSaveInPrograss = true;
    this.isSiteAddressSaveButtonClicked = this.isSiteAddressSaveButtonClicked + 1;
    if (this.manageAddressForm.valid) {
      this.onsaveAddressClick.emit({
        id: this.addressDetails.id,
        addressNo: this.manageAddressForm.controls.addressNo.value,
        addressLines: this.manageAddressForm.controls.addressLines.value,
        city: this.manageAddressForm.controls.city.value,
        postalCode: this.manageAddressForm.controls.postalCode.value,
        district: this.manageAddressForm.controls.district.value,
        state: this.manageAddressForm.controls.state.value,
        countryName: this.manageAddressForm.controls.countryForm.value.countryName,
        countryId: this.manageAddressForm.controls.countryForm.value.countryId
      });
    }
    this.isSaveInPrograss = false;
  }
  //#endregion

  //#region  methods
  private loadForm(): void {
    this.manageAddressForm.setValue({
      addressNo: this.addressDetails.addressNo,
      addressLines: this.addressDetails.addressLines,
      city: this.addressDetails.city,
      postalCode: this.addressDetails.postalCode,
      district: this.addressDetails.district,
      state: this.addressDetails.state,
      countryForm: { countryId: this.addressDetails.countryId, countryName: this.addressDetails.countryName }
    });
  }

  private createForm(): void {
    this.manageAddressForm = this.formBuilder.group({
      addressNo: ['', [Validators.required,Validators.maxLength(20)]],
      addressLines: ['', [Validators.required, Validators.maxLength(400)]],
      city: ['', [Validators.maxLength(200)]],
      postalCode: ['', [Validators.maxLength(50)]],
      district: ['', [Validators.maxLength(200)]],
      state: ['', [Validators.maxLength(200)]],
      countryForm: this.formBuilder.group({
        countryId: ['', Validators.required],
        countryName: ['', [Validators.maxLength(100)]]
      })
    });
  }
  //#endregion

}
