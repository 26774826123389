import { CustomDateParserFormatter } from './../formatters/custom-date-parser.formatter';
import { ApiAdapterService } from './../service/util/api-adapter.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LaddaModule } from 'angular2-ladda';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BlockUIModule } from 'ng-block-ui';
import { PermissionDirective } from '../directives/permission.directive';



@NgModule({
  declarations: [PermissionDirective,],
  imports: [
    CommonModule,
    LaddaModule,
    NgbModule,
    ConfirmationPopoverModule,
    BlockUIModule
  ],
  providers:[
    ApiAdapterService,
    HttpClient,
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
  ],
  exports:[
    LaddaModule,
    NgbModule,
    ConfirmationPopoverModule,
    BlockUIModule,
    PermissionDirective
  ]
})
export class SharedModule { }
