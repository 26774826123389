import { LoggedInUser } from '@models/business/logged-in-user.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JwtAuthService {

  constructor() { }

  private userPermissions: Array<string> = new Array();
  public registerUser(user: LoggedInUser): void {
    if (!user.role || !user.username || !user.authToken) {
      // navigate to the login
    }

    localStorage.removeItem('NMRA_USER');
     localStorage.removeItem('NMRA_USER_PERMISSION_LIST');

    const rowUser = JSON.stringify(user);
    localStorage.setItem('NMRA_USER', rowUser);
  }

  public fetchUser(): LoggedInUser {
    const rowUser = localStorage.getItem('NMRA_USER');
  
    if (!rowUser) { return null; }

    const user = JSON.parse(rowUser) as LoggedInUser;

    return user;
  }

  public fetchPermissionCode(): Array<string> {
    const storedArrayString = localStorage.getItem("NMRA_USER_PERMISSION_LIST"); 
    const permissionArray = storedArrayString ? JSON.parse(storedArrayString) : [];

    return permissionArray;
  }

  public logOut(): void {
    localStorage.removeItem('NMRA_USER');
     localStorage.removeItem('NMRA_USER_PERMISSION_LIST');
  }
}
