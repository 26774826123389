import { value } from 'numeral';
//#region Imports
//#region Angular
import { ControlContainer, FormGroup } from '@angular/forms';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
//#endregion

//#region Internal
import { Parameter } from '@models/util/api-adapter-service/parameter.model';
import { AppResponse } from '@models/util/api-dto/response-models/app-response';
import { ApiAdapterService } from 'src/app/service/util/api-adapter.service';
import { NotificationService } from 'src/app/service/util/notification.service';
import { CountryMetaResponse } from '@models/dto';
import { SelectOption } from '../../models/selectOption';
//#endregion
//#endregion

@Component({
  selector: 'app-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss', './../../../../../../vendor/libs/ng-select/ng-select.scss']
})
export class SelectCountryComponent implements OnInit, OnChanges {
  //#region  Variables
  @Input() isSaveButtonClicked: number;
  iscountryFormValid = true;
  public countryForm: FormGroup;
  selectOptions: Array<SelectOption> = [];
  public singleSelectValue: any;
  multipleSelectValue: Array<any> = [];
  //#endregion

  //#region constructor
  constructor(private controlContainer: ControlContainer, private apiAdapterService: ApiAdapterService
    , private notificationService: NotificationService) { }

  ngOnInit() {
    this.countryForm = this.controlContainer.control as FormGroup;
    this.loadcountryDataInitial();
    this.statusChange();
    this.subscribeCountry();
    this.getAllCountry();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isSaveButtonClicked.currentValue) {
      this.iscountryFormValid = this.countryForm.valid;
    }
  }

  subscribeCountry() {
    if (this.countryForm.controls.countryName) {
      if (this.selectOptions.length === 0) {
        this.countryForm.controls.countryId.valueChanges.subscribe(selectedValue => {
          const option = this.selectOptions.filter(country =>
            country.value === this.countryForm.controls.countryId.value
          )[0];
          this.countryForm.controls.countryName?.setValue(
            option?.label
          );
          this.countryForm.controls.countryCode?.setValue(
            option?.optionalData1
          );
        });
      }
    }
  }

  statusChange() {
    this.countryForm.controls.countryId.statusChanges.subscribe(currentStatus => {
      if (this.selectOptions.length === 0) {
        this.getAllCountry();
      }
    });
  }

  onFocus() {
    this.getAllCountry();
  }

  private loadcountryDataInitial(): void {

    // console.log(this.countryForm.controls.countryId.value);
    if (this.countryForm.controls.countryId.value) {
      this.getAllCountry();
    }
  }
  //#endregion

  //#region Service call
  private getAllCountry(): void {
    const parameterList = new Array<Parameter>();

    parameterList.push(
      {
        name: 'Content-Type',
        value: 'application/json'
      });
    parameterList.push(
      {
        name: 'currentPageSize',
        value: '1000'
      });
    parameterList.push(
      {
        name: 'pageNo',
        value: '1'
      });

    // /meta-data/meta/country/list
    this.apiAdapterService.executeApiRequest('getCountriesAsListOperation', parameterList)
      .subscribe((res: AppResponse<Array<CountryMetaResponse>>) => {
        // console.log('COUNTRIES', res);
        if (res.error) {
          this.notificationService.showErrorNotification('Error While Searching');

        } else if (!res.data) {
          this.notificationService.showWarningNotification('No Data Found');
        } else {
          const countryList = [];

          res.data.forEach(country => {
            countryList.push({
              label: country.country.countryName,
              optionalData1: country.country.countryCode,
              value: country.country.countryId,
              flag: `https://flagcdn.com/w20/${country.country.countryCode.toLowerCase()}.png`, // 'country.country.countryCode',
              timezone: 'country.country.countryId'
            });
          });

          this.selectOptions = countryList;
        }
      });
  }
}
  //#endregion

