<div class="card mb-4">
  <h6 class="card-header">
    Add/Update Address Details </h6>
  <div class="card-body">
    <div class="form-row">
      <form [formGroup]="manageAddressForm">
          <app-global-manage-address [isSaveButtonClicked]="isSiteAddressSaveButtonClicked" [formGroup]="manageAddressForm">
          </app-global-manage-address>

          <div class="row">
            <div class="col-12">
              <button [ladda]="isSaveInPrograss" type="button" (click)="onSaveClick()" data-style="expand-left"
                class="btn btn-success float-right"><span class="ion ion-md-save mr-2"></span>Save</button>
                <button type="button" mwlConfirmationPopover (confirm)="onCancelClick()" class="btn btn btn-secondary float-right mr-2">
                  <span class="ion ion-md-close-circle-outline mr-2"></span>Cancel</button>
                <!-- <button type="button" (click)="onCancelClick()" class="btn btn btn-secondary float-right mr-2"><span
                  class="ion ion-md-close-circle-outline mr-2"></span>Cancel</button> -->
            </div>
          </div>
      </form>
    </div>
  </div>
</div>
