import { Injectable } from '@angular/core';
import { LoggedInUser } from '@models/business/logged-in-user.model';
import { Parameter } from '@models/util/api-adapter-service/parameter.model';
import { AppResponse } from '@models/util/api-dto/response-models/app-response';
import ROLES from 'src/assets/auth/jwt-permission-configs.json';
import { ApiAdapterService } from '../util/api-adapter.service';
import { NotificationService } from '../util/notification.service';
import { JwtAuthService } from './jwt-auth.service';


@Injectable({
  providedIn: 'root'
})
export class JwtPermissionService {

  // private userPermissions: Array<string> = new Array();
  userRole : string;
  
  constructor(private authService: JwtAuthService, 
    private globalMasterService: ApiAdapterService,
    private notificationService: NotificationService) { }

    public isPermitted(code: string): boolean {
      const user = this.authService.fetchUser();
      // console.log('user: ', user.role)
  
      if (!user || !user.role) { return false; }
  
      const roleList = ROLES;
      // console.log('Role list: ', roleList)
  
      const permissionList = this.authService.fetchPermissionCode();
      // console.log('Role list: ', permissionList)

      //let rolePermissions = ROLES.ROLES[user.role.toUpperCase()] as Array<string>;
      let rolePermissions = permissionList;
      // console.log('Role list old: ', rolePermissions)
  
      if (!rolePermissions) { rolePermissions = ["ROUTE_DASHBOARD"]; }
  
      return rolePermissions.some(p => p === code);
    }

    
    // Setter for userName set userName(name: string) { this._userName = name; }
  // getUserPermissionCode(userRole: string): Promise<string | null> {
  //   return new Promise((resolve, reject) => {
  //     const parameterList = [
  //       {
  //         name: 'roleName',
  //         value: userRole
  //       }
  //     ];
  
  //     this.globalMasterService.executeApiRequest('getPermissionByRoleName', parameterList)
  //       .subscribe(
  //         (res: AppResponse<string>) => {
  //           if (res?.data) {
  //             console.log('res: ' + res.data);
  //             resolve(res.data);
  //           } else {
  //             this.notificationService.showWarningNotification('No Data Found');
  //             resolve(null); // No data was found
  //           }
  //         },
  //         (error) => {
  //           this.notificationService.showErrorNotification('Error fetching permissions');
  //           reject(error);
  //         }
  //       );
  //   });
  // }
}
