import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { JwtPermissionService } from '../service/auth/jwt-permission.service';
// import { PermissionService } from '@core/services/permission.service';

@Directive({
  selector: '[permission]'
})
export class PermissionDirective {
  @Input() set permission(code: string) {
    if (this.permissionService.isPermitted(code)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: JwtPermissionService) { }
}