<form [formGroup]="addOrUpdateOfficeAddressDetailsForm">
  <div class="form-row">
    <!--Address No-->
    <div class="form-group col-md-3">
      <label class="form-label">Address No.</label><span class="required">*</span>
      <input
        [ngClass]="(!isAddOrUpdateOfficeAddressDetailsValid && addOrUpdateOfficeAddressDetailsForm?.controls?.addressNo?.errors)?'form-control is-invalid':'form-control'"
        type="text" class="form-control" placeholder="Address No." formControlName="addressNo">
      <small class="invalid-feedback"
        *ngIf="addOrUpdateOfficeAddressDetailsForm?.controls?.addressNo?.errors?.required">Address No. is
        Required</small>
      <small class="invalid-feedback"
        *ngIf="addOrUpdateOfficeAddressDetailsForm?.controls?.addressNo?.hasError('maxlength')">Maximum 20 characters
        allowed</small>
    </div>
    <!--Address Lines-->
    <div class="form-group col-md-9">
      <label class="form-label">Address Lines</label><span class="required">*</span>
      <input
        [ngClass]="(!isAddOrUpdateOfficeAddressDetailsValid && addOrUpdateOfficeAddressDetailsForm?.controls?.addressLines?.errors)?'form-control is-invalid':'form-control'"
        type="text" class="form-control" placeholder="Address Lines" formControlName="addressLines">
      <small class="invalid-feedback"
        *ngIf="addOrUpdateOfficeAddressDetailsForm?.controls?.addressLines?.errors?.required">Address Line is
        Required</small>
      <small class="invalid-feedback"
        *ngIf="addOrUpdateOfficeAddressDetailsForm?.controls?.addressLines?.hasError('maxlength')">Maximum 400
        characters allowed</small>
    </div>
    <!-- City-->
    <div class="form-group col-md-4">
      <label class="form-label">City</label>
      <input
        [ngClass]="(!isAddOrUpdateOfficeAddressDetailsValid && addOrUpdateOfficeAddressDetailsForm?.controls?.city?.errors)?'form-control is-invalid':'form-control'"
        type="text" class="form-control" placeholder="City" formControlName="city">
      <small class="invalid-feedback"
        *ngIf="addOrUpdateOfficeAddressDetailsForm?.controls?.city?.hasError('maxlength')">Maximum 200 characters
        allowed</small>
    </div>
    <!--Postal Code-->
    <div class="form-group col-md-3">
      <label class="form-label">Postal Code</label>
      <input
        [ngClass]="(!isAddOrUpdateOfficeAddressDetailsValid && addOrUpdateOfficeAddressDetailsForm?.controls?.postalCode?.errors)?'form-control is-invalid':'form-control'"
        type="text" class="form-control" placeholder="Postal Code" formControlName="postalCode">
      <small class="invalid-feedback"
        *ngIf="addOrUpdateOfficeAddressDetailsForm?.controls?.postalCode?.hasError('maxlength')">Maximum 50 characters
        allowed</small>
    </div>
    <!--District-->
    <div class="form-group col-md-5">
      <label class="form-label">District</label>
      <input
        [ngClass]="(!isAddOrUpdateOfficeAddressDetailsValid && addOrUpdateOfficeAddressDetailsForm?.controls?.district?.errors)?'form-control is-invalid':'form-control'"
        type="text" class="form-control" placeholder="District" formControlName="district">
      <small class="invalid-feedback"
        *ngIf="addOrUpdateOfficeAddressDetailsForm?.controls?.district?.hasError('maxlength')">Maximum 200 characters
        allowed</small>
    </div>
    <!--State-->
    <div class="form-group col-md-6">
      <label class="form-label">State</label>
      <input
        [ngClass]="(!isAddOrUpdateOfficeAddressDetailsValid && addOrUpdateOfficeAddressDetailsForm?.controls?.state?.errors)?'form-control is-invalid':'form-control'"
        type="text" class="form-control" placeholder="State" formControlName="state">
      <small class="invalid-feedback"
        *ngIf="addOrUpdateOfficeAddressDetailsForm?.controls?.state?.hasError('maxlength')">Maximum 200 characters
        allowed</small>
    </div>
    <!--Country-->
    <div class="form-group col-md-6">
      <label class="form-label">Country</label><span class="required">*</span>
      <app-select-country [isSaveButtonClicked]="isSaveButtonClicked"
        [formGroup]="addOrUpdateOfficeAddressDetailsForm.controls.countryForm"> </app-select-country>
      <small class="invalid-feedback"
        *ngIf="addOrUpdateOfficeAddressDetailsForm?.controls?.country?.errors?.required">Country is
        Required</small>
    </div>
  </div>
</form>