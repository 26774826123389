<sidenav [orientation]="orientation" [ngClass]="getClasses()">

    <!-- Inner -->
    <div class="sidenav-inner" [ngClass]="{ 'py-1': orientation !== 'horizontal' }">

        <sidenav-router-link *permission="'ROUTE_DASHBOARD'" icon="ion ion-md-speedometer" route="/medicine-db/dashboard" [active]="isActive('/medicine-db/dashboard')">Dashboard</sidenav-router-link>
        <sidenav-router-link *permission="'ROUTE_MEDICINE'" icon="ion ion-md-medkit" route="/medicine-db/medicine/search-medicine" [active]="isActive('/medicine-db/medicine/search-medicine')">Medicine</sidenav-router-link>
        <sidenav-router-link *permission="'ROUTE_GENERIC_NAME'" icon="ion ion-md-clipboard" route="/medicine-db/generic-name/search-generic-names" [active]="isActive('/medicine-db/generic-name/search-generic-names')">Generic Name</sidenav-router-link>
        <sidenav-router-link *permission="'ROUTE_CLIENTS'" icon="ion ion-md-people" route="/medicine-db/client/search-client" [active]="isActive('//medicine-db/client/search-client')">Clients</sidenav-router-link>
        <sidenav-router-link *permission="'ROUTE_MASTER_DATA'" icon="ion ion-md-filing" route="/medicine-db/master-data" [active]="isActive('/medicine-db/master-data')">Master Data</sidenav-router-link>

        <sidenav-menu *permission="'ROUTE_USER_MANAGEMENT_USERS'" icon="ion ion-md-person" text="User Management" [active]="isMenuActive('/medicine-db/user')" [open]="isMenuOpen('/medicine-db/user')">
            <sidenav-router-link *permission="'ROUTE_USER_MANAGEMENT_USERS'" route="/medicine-db/user/search-user" [active]="isActive('//medicine-db/user/search-user')">Users</sidenav-router-link>
            <!-- <sidenav-router-link *permission="'ROUTE_USER_MANAGEMENT_ROLS'" route="/medicine-db/user/user-roles" [active]="isActive('/medicine-db/user/user-roles')">User Rols</sidenav-router-link> -->
        </sidenav-menu>

        <sidenav-router-link *permission="'ROUTE_REPORTS_ALL'" icon="ion ion-md-paper" route="/medicine-db/reports" [active]="isActive('/medicine-db/reports')">Reports</sidenav-router-link>

        <sidenav-menu *permission="'ROUTE_HISTORY_MEDICINE' || 'ROUTE_HISTORY_CLIENTS' || 'ROUTE_HISTORY_MASTER_DATA'"  icon="ion ion-md-recording" text="Update History" [active]="isMenuActive('/update-history')" [open]="isMenuOpen('/update-history')">
            <sidenav-router-link *permission="'ROUTE_HISTORY_MEDICINE'" route="/medicine-db/update-history/medicine-history" [active]="isActive('/medicine-db/update-history/medicine-history')">Medicine</sidenav-router-link>
            <sidenav-router-link *permission="'ROUTE_HISTORY_CLIENTS'" route="/medicine-db/update-history/clients-history" [active]="isActive('/medicine-db/update-history/clients-history')">Clients</sidenav-router-link>
            <sidenav-router-link *permission="'ROUTE_HISTORY_MASTER_DATA'" route="/medicine-db/update-history/master-data-history" [active]="isActive('/medicine-db/update-history/master-data-history')">Master Data</sidenav-router-link>
        </sidenav-menu>
    </div>
</sidenav>
